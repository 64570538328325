var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid,
          pristine = _ref.pristine;
        return [_c('b-modal', {
          ref: "modalEdit",
          attrs: {
            "id": "modify-note-modal",
            "header-bg-variant": "light-info",
            "centered": "",
            "size": "md"
          },
          scopedSlots: _vm._u([{
            key: "modal-header",
            fn: function fn() {
              return [_c('h4', {
                staticClass: "text-heading-3 m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('reservation.note')) + " ")])];
            },
            proxy: true
          }, {
            key: "modal-footer",
            fn: function fn() {
              return [_c('b-button', {
                attrs: {
                  "variant": "outline-danger",
                  "pill": "",
                  "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
                },
                on: {
                  "click": _vm.handleCancelEdit
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('customer.cancel')) + " ")]), _c('b-button', {
                attrs: {
                  "variant": "gradient",
                  "pill": "",
                  "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs',
                  "disabled": invalid || pristine
                },
                on: {
                  "click": _vm.handleEdit
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('customer.save')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form', {
          ref: "form"
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Last Name"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', [_c('b-form-textarea', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "rows": "4",
                  "placeholder": _vm.$t('reservation.notePlaceholder')
                },
                model: {
                  value: _vm.dataToEdit.notePrivate,
                  callback: function callback($$v) {
                    _vm.$set(_vm.dataToEdit, "notePrivate", $$v);
                  },
                  expression: "dataToEdit.notePrivate"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1)], 1)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }